/**
 * @module runtime-app
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import './runtime-app.scss';

import { loadBrowserLanguage } from 'translator/translator';
import { bootstrapFailed } from 'apps/common/bootstrap-failed';

async function init() {
	// load translations
	loadBrowserLanguage();

	// disable double-click (to prevent zooming on iOS)
	document.ondblclick = (e) => e.preventDefault();

	try {
		// working around webpack bug with WASM
		// see https://github.com/webpack/webpack/issues/6615
		await import('./runtime-app');
	} catch (e) {
		bootstrapFailed('play', e);
	}
}

void init();
